import { ref } from 'vue'
import { useAPI, useContextData } from '#imports'

export function useExpertsStore () {
  const api = useAPI()
  const ratingsDescriptions = ref<Record<string, string | undefined>>({})

  async function getRatingDescription (code: string) {
    const info = useContextData<any>(`expert-${code}`)

    if (!info.value) {
      const { getRatingDescription } = api.catalog()

      info.value = await getRatingDescription(code)
    }

    return info.value
  }

  return {
    getRatingDescription,
    ratingsDescriptions
  }
}
